import { debug, error } from "$lib/log";
import { pb, USERS_COLLECTION } from "$lib/pocketbase-client";

if(pb.authStore.isValid) {
    // refresh the auth token
    pb.collection(USERS_COLLECTION).authRefresh().then(() => {
        debug("Auth token has been refreshed");
    }).catch((err) => {
        error("Failed to refresh the auth token", err);
        pb.logout();
    });
}